import * as React from "react"
import { SEO } from "../components/seo"
import Layout from '../components/layout'

const ContactPage = () => {
  return (
	<Layout>
		<main>
			<section id="hero">
				<h1>Contact Page</h1>
			</section>
			<section id="intro">
				<p>This is a contact page.</p>
			</section>
		</main>
	</Layout>
  )
}
export default ContactPage

export const Head = () => (
	<SEO
        title="Contact Page"
        description="This is the contact page of the sitekeeper template."
    />
)
